import axios from "axios";
import VehicleRequestBuilders from "../RequestBuilders/vehicles";

class Vehicles {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.vehicleRequestBuilder = new VehicleRequestBuilders();
  }
  addVehicle = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.VEHICLES;
    let reqData = this.vehicleRequestBuilder.AddVehicles(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addVehiclesData = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/addVehicles`;
    let reqData = this.vehicleRequestBuilder.AddVehicle(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addOwenVehicle = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/addVehicles`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFleetVendorVehicles = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.VEHICLES + "/fleetVendorVehicles",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVehicles = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.VEHICLES, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getLatestTrip = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VEHICLES +
      `/${data.vehicleId}/getLatestTrip`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFuelQuantity = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.VEHICLES + "/getFuelQuantity",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVehiclesData = (data) => {
    let s = "";
    Object.keys(data).map((i) => {
      s += `${i}=${data[i]}&`;
    });
    let formateData = s.slice(0, -1);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/owner/myTruckListV4?${formateData}`;
      // owner/myTruckListV4?vehicleType=market
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getMyVehicles = () => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_6 + "/truckStatus";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getMyVehiclesV2 = () => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "vehicles/trackItv2";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getLocationString = (data) => {
    const url = "https://nominatim.openstreetmap.org/reverse";
    return new Promise((resolve, reject) => {
      return axios({
        method: "get",
        url: url,
        headers: {},
        params: {
          lat: data.lat,
          lon: data.lng,
          format: "json",
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTrackingMessages = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      "/tracking" +
      "/get-messages";
    return new Promise((resolve, reject) => {
      return this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTimeline = (id,data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/${id}/timeline`;
    return new Promise((resolve, reject) => {
      return this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTruckDriverId = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + `owner/get-truck-driver?truck_id=${data}`;

    return new Promise((resolve, reject) => {
      return this.httpService
      .get(url)    
      .then((res)=>{
        resolve(res)
      })
      .catch((error)=>{
        reject(error)
      })
      
    });
  };


  getAssignableDrivers = () => {
    const getOwnerID = JSON.parse(sessionStorage.getItem("profile")).id;

    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `reports/get-Drivers`
    return new Promise((resolve, reject) => {
      return this.httpService
      .get(url)    
      .then((res)=>{
        resolve(res)
      })
      .catch((error)=>{
        reject(error)
      })
      
    });
  };

  getDriverDetrails = (value) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1+ `vehicles/${value}/getAssignedDriver`
    return new Promise((resolve, reject) => {
      return this.httpService
      .get(url)    
      .then((res)=>{
        resolve(res)
      })
      .catch((error)=>{
        reject(error)
      })
      
    });
  };

  getMobileNumber = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/vehicleRequest/` + data
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVehiclesType = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/vehicleType`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getDeliveryOrdersList = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `deliveryorders/list`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getIndividualVehicleLive = (flespiId) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/${flespiId}/live`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTruckTypes = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/truckTypes`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVehiclesDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      `vehicles/getVehiclesDetails/${data}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getManufacturers = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/manufacturers`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTireManufacturers = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      `vehicles/tireManufacturers`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getMakeModels = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/vehicleModels`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVehicleCategory = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/vehicleCategory`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getLoadCapacity = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/loadCapacity`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getBodyTypes = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/bodyTypes`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getPermitTypes = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/permitTypes`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTireDetails = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/tyre/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getGetBatteryDetails = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      `vehicles/getBatteryDetails/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getOtherMaintenanceDetails = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      `vehicles/otherMaintenanceDetails/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getMaintenanceComplienceDetails = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      `vehicles/getMaintenanceComplience/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addMaintenanceCompliance = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/addMaintenanceCompliance`;
    return new Promise((resolve, reject) => {
      return this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTirePositions = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      `vehicles/tirePositions?vehicleCatId=${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateTireInfo = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/tyre`;
    return new Promise((resolve, reject) => {
      return this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getCompliances = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/compliances`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addBatteryDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/battery`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateBatteryDetails = (id, data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/battery/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addOtherMaintanance = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/addOtherMaintanance`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateOtherMaintanance = (id, data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/updateOtherMaintanance/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateExchangeTire = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/exchangeTire`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateReplaceTire = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/replaceTire`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  uploadTruckImage = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/owner/uploadTruckImage`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateMaintananceCompliance = (id, data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/updateMaintananceCompliance/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateBasicDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/updateBasicDetails`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateOtherDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/updateOtherDetails`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateCostDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/costDetails`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateBenchMarksDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/benchMarksDetails`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateInsuranseDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/insuranseDetails`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addRtoCertificate = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/addCertificate`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateRtoCertificate = (id, data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/updateCertificates/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getRtoCertificates = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      `vehicles/certificates/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  tireTopPicUpload = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/tyre/tireTopPicUpload`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  tireFrontPicUpload = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vehicles/tyre/tireFrontPicUpload`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getAllVehicles = (data) => {
    let getData = data && data ? `vehicles?allVehicles=true&ownVehicles=${data.ownVehicles}&marketVehicles=${data.marketVehicles}` : `vehicles?allVehicles=true`;
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + getData;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getAllCategories = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `alerts/categories`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getSubCategories = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `alerts/categories/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getAllAlerts = (data) => {
    let s = "";
    Object.keys(data).map((i) => {
      s += `${i}=${data[i]}&`;
    });
    let formateData = s.slice(0, -1);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `alerts?${formateData}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getLoadersRouteHistory = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/loaders/routeHistory`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url,data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  removeTruckImage = (data) => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `owner/removeTruckImage`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVehicleGates = () => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/gates`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFuelSencerEfficients = (id) => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/fuelCoefficients/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getLiveEquipments = () => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "equipments/live";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
  getSelectedVehicleEquipmentsDetails = (id) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicleInventory/truckEquipmentsList?truckId=${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getEquipmentAlerts = (id,data,mts) => {
    debugger
    let getUrl = mts && mts.min !== null && mts.max !== null ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `equipments/alerts?minDistance=${mts?.min}&maxDistance=${mts.max}&skip=${data.skip}&limit=${data.limit}&&truckId=${id}`:process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `equipments/alerts?skip=${data.skip}&limit=${data.limit}&truckId=${id}`;
    const url = getUrl
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addReasongForEquipment = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/equipments/alerts/addReason`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export default Vehicles;
