import React from "react";
import $ from "jquery";
// import { Receipt } from "@material-ui/icons";
// import { exact } from "prop-types";
// import { imageOverlay } from "leaflet";
import { EditUserProfile } from "../../pages/profile/editProfile";
import BenchmarksList from "../../pages/profile/benchmarks";
import InventoryCategory from "../../pages/profile/inventoryCategory";
import { ForSuccessMessage, OtpVerification } from "../../verification.js/verification";
import { RegistrationForm } from "../../register.js/registration";
import { ForgotPassword } from "../../forgotPassword.js/forgotpassword";
import ForStoreInventory from "../../pages/forStoreInventory/storeInventory";
import CollapsibleTableForTransactions from "../../pages/InventoryTransactions/transactionsList";
import { MyDevice } from "../../pages/devices/myDevices"; 
import { AddDevice } from "../../pages/devices/addDevice";
// import { DeviceViewDetails } from "../../pages/devices/deviceViewDetails";
import { exact } from "prop-types";
import DeviceViewDetails from "../../pages/devices/deviceViewDetails";
import DeviceLiveData from "../../pages/devices/deviceLiveData";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const Dashboard = React.lazy(() => import("../../pages/dashboard/dashboard"));
// const Signin = React.lazy(() => import('../../pages/signin/signin'));
const TripsheetList = React.lazy(() =>
  import("../../pages/tripsheet/TripsheetList")
);
const RequestList = React.lazy(() =>
  import("../../pages/VehicleRequest/RequestList")
);

// const Request = React.lazy(() => import("../../pages/VehcileRequest/Request"));
const CreateTripsheet = React.lazy(() =>
  import("../../pages/tripsheet/CreateTripsheet")
);
const VehcileReqVerify = React.lazy(() =>
  import("../../pages/Approval/VechileReqVerify")
);
const NewVehcileList = React.lazy(() =>
  import("../../pages/Approval/NewVechileList")
);
const TransferVechileList = React.lazy(() =>
  import("../../pages/Approval/TransferVechileList")
);
const RemoveVehcileList = React.lazy(() =>
  import("../../pages/Approval/RemoveVechileList")
);
const ExchangeVechileList = React.lazy(() =>
  import("../../pages/Approval/ExchangeVehicle")
);
const CtrlVehcileRequestVerify = React.lazy(() =>
  import("../../pages/ControlRoom/CtrlVehcileRequestVerify")
);
const CtrlNewVehcileList = React.lazy(() =>
  import("../../pages/ControlRoom/CtrlNewVehcileList")
);
const CtrlTransferVehcileList = React.lazy(() =>
  import("../../pages/ControlRoom/CtrlTransferVechileList")
);
const CtrlRemoveVehcileList = React.lazy(() =>
  import("../../pages/ControlRoom/CtrlRemoveVechileList")
);
const CtrlExchangeVehcileList = React.lazy(() =>
  import("../../pages/ControlRoom/CtrlExchangeVehicle")
);
const TripsheetSummary = React.lazy(() =>
  import("../../pages/tripsheet/TripsheetSummary/index")
);
const Trip = React.lazy(() => import("../../pages/trips/Trip"));
const AddTrip = React.lazy(() =>
  import("../../pages/trips/createTrip/AddTrip")
);
const AddTrips = React.lazy(()=> import("../../pages/trips/createTrip/AddTripForMining"))
const PendingTrips = React.lazy(() => import("../../pages/trips/PendingTrips"));
const FindTrips = React.lazy(() => import("../../pages/trips/FindTrips"));
const EditTrip = React.lazy(() => import("../../pages/trips/EditTrip"));
const CloseTrip = React.lazy(() => import("../../pages/trips/CloseTrip"));
const UpdateTrip = React.lazy(() =>
  import("../../pages/trips/createTrip/UpdateTrip")
);
const TripDetails = React.lazy(() => import("../../pages/trips/TripDetails"));
const ClientList = React.lazy(() => import("../../pages/clients/ClientList"));
const ViewInvoice = React.lazy(() =>
  import("../../pages/clients/invoices/ViewInvoice")
);
const Vendor = React.lazy(() => import("../../pages/vendor/vendor"));
const AddVendor = React.lazy(() => import("../../pages/vendor/AddVendor"));
const AddClient = React.lazy(() => import("../../pages/clients/AddClient"));
const ClientBookings = React.lazy(() =>
  import("../../pages/clients/ClientBookings")
);
const DriverList = React.lazy(() => import("../../pages/drivers/DriverList"));
const DriverPayments = React.lazy(() =>
  import("../../pages/drivers/DriverPayments")
);
const MakePayment = React.lazy(() => import("../../pages/drivers/MakePayment"));
const AddDriver = React.lazy(() => import("../../pages/drivers/AddDriver"));
const VendorDetails = React.lazy(() =>
  import("../../pages/vendor/VendorDetails")
);
const AddPilot = React.lazy(()=>import ("../../pages/drivers/AddPilot"));
const EditPilot = React.lazy(()=>import("../../pages/drivers/EditPilot"));
const AdvanceList = React.lazy(() => import("../../pages/vendor/AdvanceList"));
const DueList = React.lazy(() => import("../../pages/vendor/DueList"));
const LedgerList = React.lazy(() => import("../../pages/vendor/LedgerList"));
const RouteList = React.lazy(() =>
  import("../../pages/clients/contracts/routes/RouteList")
);
const ContractList = React.lazy(() =>
  import("../../pages/clients/contracts/ContractList")
);
const AddRoute = React.lazy(() =>
  import("../../pages/clients/contracts/routes/AddRoute")
);
// const Vehicles =  React.lazy(() =>
// import("../../pages/drivers/Nav")
// );
// const EditVehicle =  React.lazy(() =>
// import("../../pages/drivers/EditPage")
// );
const EditVehicle = React.lazy(() =>
  import("../../pages/Myvehicles/UpdateVehicle/EditVehicle")
);
const addContract = React.lazy(() =>
  import("../../pages/clients/contracts/AddContract")
);
const editContract = React.lazy(()=> import("../../pages/clients/contracts/EditContract"))
const ClientDetails = React.lazy(() =>
  import("../../pages/clients/ClientDetails")
);
const InvoiceList = React.lazy(() =>
  import("../../pages/clients/invoices/InvoiceList")
);
const GenerateInvoice = React.lazy(() =>
  import("../../pages/clients/invoices/GenerateInvoice")
);
const CreateInvoice = React.lazy(() =>
  import("../../pages/clients/invoices/CreateInvoice")
);
const ViewReceivables = React.lazy(() =>
  import("../../pages/clients/recievables/ViewRecievables")
);
const UpdatePayments = React.lazy(() =>
  import("../../pages/clients/recievables/UpdatePayments")
);
const ViewPayment = React.lazy(() =>
  import("../../pages/clients/recievables/ViewPayment")
);
const ContractDetails = React.lazy(() =>
  import("../../pages/clients/contracts/ContractDetails")
);
const DeliveryOrderList = React.lazy(() =>
  import("../../pages/clients/contracts/delivery_orders/DeliveryOrderList")
);
const FleetContractDeliveryOrderList = React.lazy(()=> import("../../pages/clients/contracts/delivery_orders/DeliveryOrderList"))
const DeliveryOrderDetails = React.lazy(() =>
  import("../../pages/clients/contracts/delivery_orders/DeliveryOrderDetails")
);
const AddDeliveryOrder = React.lazy(() =>
  import("../../pages/clients/contracts/delivery_orders/AddDeliveryOrder")
);
const CreateDeliveryOrder = React.lazy(()=>
  import("../../pages/DeliveryOrders/AddDo")
)
const EditDeliveryOrder = React.lazy(() =>
import("../../pages/clients/contracts/delivery_orders/EditDeliveryOrder")
);
const RouteDetails = React.lazy(() =>
  import("../../pages/clients/contracts/routes/RouteDetails")
);
const TransferList = React.lazy(() =>
  import("../../pages/VehicleRequest/TransferList")
);
const VehicleList = React.lazy(() =>
  import("../../pages/VehicleRequest/NewVehiclesRequest")
);
const Indent = React.lazy(() => import("../../pages/indent/Indent"));
const AddIndent = React.lazy(() => import("../../pages/indent/AddIndent"));
const CompanyUsers = React.lazy(() => import("../../pages/users/CompanyUsers"));
const FleetUsers = React.lazy(() => import("../../pages/users/FleetUsers"));
const AddUser = React.lazy(() => import("../../pages/users/AddUser"));
const Groups = React.lazy(() => import("../../pages/groups/Groups"));
const GroupView = React.lazy(() => import("../../pages/groups/GroupView"));
const AddGroup = React.lazy(() => import("../../pages/groups/AddGroup"));
const FleetVendor = React.lazy(() =>
  import("../../pages/vendors/FleetVendor/FleetVendor")
);
const ServiceVendor = React.lazy(() =>
  import("../../pages/vendors/ServiceVendor/ServiceVendor")
);
const AddFleet = React.lazy(() =>
  import("../../pages/vendors/FleetVendor/AddFleet")
);
const AddService = React.lazy(() =>
  import("../../pages/vendors/ServiceVendor/AddService")
);
const ServiceContractList = React.lazy(() =>
  import(
    "../../pages/vendors/ServiceVendor/servicecontracts/ServiceContractList"
  )
);
const AddServiceContract = React.lazy(() =>
  import(
    "../../pages/vendors/ServiceVendor/servicecontracts/AddServiceContract"
  )
);
const ServiceIndentList = React.lazy(() =>
  import("../../pages/vendors/ServiceVendor/serviceindent/ServiceIndentList")
);
const ServiceDetails = React.lazy(() =>
  import("../../pages/vendors/ServiceVendor/ServiceDetails")
);
const ServiceContractDetails = React.lazy(() =>
  import(
    "../../pages/vendors/ServiceVendor/servicecontracts/ServiceContractDetails"
  )
);
const AddFleetVender = React.lazy(() =>
  import("../../pages/VehicleRequest/AddFleetVender")
);
const ExchangeVehicle = React.lazy(() =>
  import("../../pages/VehicleRequest/ExchangeVehicle")
);

const Transfer = React.lazy(() =>
  import("../../pages/VehicleRequest/Transfer")
);

const Remove = React.lazy(() => import("../../pages/VehicleRequest/Remove"));

const Sites = React.lazy(() => import("../../pages/sites/SitesList"));
const AddSite = React.lazy(() => import("../../pages/sites/AddSite"));
const ViewSite = React.lazy(() => import("../../pages/sites/ViewSite"));
const FleetDetails = React.lazy(() =>
  import("../../pages/vendors/FleetVendor/FleetDetails")
);
const EditFleet = React.lazy(() =>
  import("../../pages/vendors/FleetVendor/EditFleet")
);
const FleetContractList = React.lazy(() =>
  import("../../pages/vendors/FleetVendor/FleetContract/FleetContractList")
);
const FleetCustmerContract = React.lazy(() =>
  import("../../pages/vendors/FleetVendor/FleetContract/FleetCustomerContract") 
);
const AddFleetContract = React.lazy(() =>
  import(
    "../../pages/vendors/FleetVendor/FleetContract/AddFleetContract/AddFleetContract"
  )
);
const AddFleetCustomerContract = React.lazy(() =>
  import(
    "../../pages/vendors/FleetVendor/FleetContract/AddFleetCustomerContract"
  )
);
const EditFleetContract = React.lazy(() =>
  import(
    "../../pages/vendors/FleetVendor/FleetContract/UpdateFleetContract/EditFleetContract"
  )
);
const MyFleet = React.lazy(() => import("../../pages/myfleet"));
const MyFleetHealthCare = React.lazy(()=> import(`../../pages/myFleetForHealthCare`))
const FleetContractDetails = React.lazy(() =>
  import("../../pages/vendors/FleetVendor/FleetContract/FleetContractDetails")
);
const VehicleTracking = React.lazy(() => import("../../pages/myfleet/Trackit"));
const liveVehicleTracking = React.lazy(()=> import(`../../pages/myFleetForHealthCare/Trackit`))
const DriverTripsheets = React.lazy(() =>
  import("../../pages/drivers/DriverTripsheets")
);
const BillingAndPayments = React.lazy(() =>
  import("../../pages/clients/BillingAndPayments")
);
const ServiceProviderPayments = React.lazy(() =>
  import("../../pages/payments/ServiceProviderPayments")
);
const VendorPayments = React.lazy(() =>
  import("../../pages/payments/vendorPayments/index")
);
const VendorIndents = React.lazy(() =>
  import("../../pages/payments/VendorIndents")
);
const GenerateVoucher = React.lazy(() =>
  import("../../pages/payments/vendorPayments/GenerateVoucher")
);
const AllRoutes = React.lazy(() => import("../../pages/routes/RouteList"));
const PilotPayments = React.lazy(() =>
  import("../../pages/pilotpayments/Index")
);
const PilotLedger = React.lazy(() =>
  import("../../pages/pilotpayments/pilotLedger/index")
);
const PilotTrips = React.lazy(() =>
  import("../../pages/pilotpayments/pilotLedger/Pilottrips")
);
const AddAdvance = React.lazy(() =>
  import("../../pages/pilotpayments/pilotLedger/PayAdvance")
);
const AddExpense = React.lazy(() =>
  import("../../pages/pilotpayments/pilotLedger/AddExpenses")
);
const PettyCash = React.lazy(() =>
  import("../../pages/pettycash/payments/Index")
);
const FleetVendorPayments = React.lazy(() =>
  import("../../pages/FleetVendorPayments/FleetVendorPayments")
);
const VendorPaymentsDetails = React.lazy(() =>
  import("../../pages/FleetVendorPayments/VendorPaymentDetails/Index")
);

const RemoveList = React.lazy(() =>
  import("../../pages/VehicleRequest/RemoveList")
);
const ExchangeList = React.lazy(() =>
  import("../../pages/VehicleRequest/ExchangeVehicleList")
);

const AddPettyCashPayment = React.lazy(() =>
  import("../../pages/pettycash/AddPayment")
);
const PilotSettlements = React.lazy(() =>
  import("../../pages/pilotpayments/pilotLedger/PilotSettlements")
);
const SettlementTrips = React.lazy(() =>
  import("../../pages/pilotpayments/pilotLedger/SettlementTrips")
);
const OwnerBanks = React.lazy(() => import("../../pages/banks/index"));
const AddBank = React.lazy(() => import("../../pages/banks/AddBank"));
const Report = React.lazy(() => import("../../pages/ledgers/Report"));
const JournalTransactions = React.lazy(() =>
  import("../../pages/journaltransactions/index")
);
const AddJournalTransaction = React.lazy(() =>
  import("../../pages/journaltransactions/AddJournalTransaction")
);

const AllContractsList = React.lazy(() =>
  import("../../pages/contracts/AllContractList")
);
const AllDeliveryOrderList = React.lazy(() =>
  import("../../pages/DeliveryOrders/AllDeliveryOrderList")
);
const DeliveryOrdersDetails = React.lazy(() =>
  import("../../pages/DeliveryOrders/DeliveryOrdersDetails")
);
const AllContractsListdetails = React.lazy(() =>
  import("../../pages/contracts/AllContractListdetails")
);
const FuelReport = React.lazy(() => import("../../pages/reports/fuelreport"));
const FuelConsumption = React.lazy(() =>
  import("../../pages/reports/FuelConsumptionReport")
);
const DistanceSummaryReport = React.lazy(()=>
import("../../pages/reports/DistanceSummaryReport"))
const Breakdownreport = React.lazy(()=>
  import("../../pages/reports/Breakdownreport"));
const Emptyvehiclewaitingreport = React.lazy(()=>
  import("../../pages/reports/EmptyVehicleWaitingReport"));
const ProfitAndLossReport = React.lazy(()=>
  import("../../pages/reports/PandLreport"));
const SpeedReport = React.lazy(()=>
  import("../../pages/reports/Speedreport"));
const SpeedwiseReport = React.lazy(()=>
  import("../../pages/reports/SpeedwiseReport"));
const IgnitionReport = React.lazy(()=>
import("../../pages/reports/Ignitionreport"));
const   DistanceReport = React.lazy(()=>
import("../../pages/reports/Distancereports"));
const TrackHistoryReport = React.lazy(()=>
import("../../pages/reports/Trackhistoryreport"));
const HourlyPingReport = React.lazy(()=>
  import("../../pages/reports/HourlypingReport"));
const TripsReport = React.lazy(() => import("../../pages/reports/TripsReport"));

const Category = React.lazy(() =>
  import("../../components/header/CategoryComponent")
);
const FuelOdoSummay = React.lazy(() =>
  import("../../pages/trips/FuelOdo/Summary/Index")
);
const Tollsummary = React.lazy(() => import("../../pages/trips/TollSummary"));

const PilotList = React.lazy(()=>import ("../../pages/Pilots/PilotsList"));
const VehiclesList = React.lazy(() =>
  import("../../pages/Myvehicles/Vehicles/Index")
);

const StoreInvetory = React.lazy(() =>
  import("../../pages/forStoreInventory/storeInventory")
);
const routes = [
  {
    path: ["/", "/dashboard"],
    exact: true,
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: ["/category"],
    exact: true,
    name: "Category",
    component: Category,
  },
  {
    path: "/tripsheet",
    exact: true,
    name: "tripsheet",
    component: TripsheetList,
  },
  {
    path: "/createTripsheet",
    exact: true,
    name: "createTripsheet",
    component: CreateTripsheet,
  },
  {
    path: "/tripsheetSummary",
    exact: true,
    name: "tripsheetSummary",
    component: TripsheetSummary,
  },
  { path: "/Trip", exact: true, name: "trip", component: Trip },
  { path: "/addTrip", exact: true, name: "addTrip", component: AddTrip },
  { path: "/addTrips", exact: true, name: "addTrip", component: AddTrips },
  {
    path: "/pendingTrips",
    exact: true,
    name: "addTrip",
    component: PendingTrips,
  },
  { path: "/findTrips", exact: true, name: "addTrip", component: FindTrips },
  { path: "/editTrip", exact: true, name: "editTrip", component: EditTrip },
  { path: "/closeTrip", exact: true, name: "closeTrip", component: CloseTrip },
  {
    path: "/updateTrip",
    exact: true,
    name: "updateTrip",
    component: UpdateTrip,
  },
  {
    path: "/tripDetails",
    exact: true,
    name: "tripDetails",
    component: TripDetails,
  },
  { path: "/clients", exact: true, name: "clients", component: ClientList },
  { path: ["/", "/vendor"], exact: true, name: "vendor", component: Vendor },
  { path: "/addVendor", exact: true, name: "addVendor", component: AddVendor },
  {
    path: ["/addClient", "/updateClient"],
    exact: true,
    name: "addClient",
    component: AddClient,
  },
  {
    path: "/addFleetVender",
    expect: true,
    name: "AddFleetVender",
    component: AddFleetVender,
  },
  {
    path: "/exchange",
    expect: true,
    name: "ExchangeVehicle",
    component: ExchangeVehicle,
  },
  {
    path: "/transfer",
    expect: true,
    name: "Transfer",
    component: Transfer,
  },
  {
    path: "/remove",
    expect: true,
    name: "Remove",
    component: Remove,
  },

  {
    path: "/clientBookings",
    exact: true,
    name: "clientBookings",
    component: ClientBookings,
  },
  { path: "/drivers", exact: true, name: "drivers", component: DriverList },
  {
    path: "/driverPayments",
    exact: true,
    name: "driverPayments",
    component: DriverPayments,
  },
  {
    path: "/makeDriverPayment",
    exact: true,
    name: "makeDriverPayment",
    component: MakePayment,
  },
  { path: "/addDriver", exact: true, name: "addDriver", component: AddDriver },
  { path: "/AddPilot", exact: true, name: "addPilot", component: AddPilot },
  { path: "/EditPilot", exact: true, name: "editPilot", component: EditPilot },
  {
    path: "/vendorDetails",
    exact: true,
    name: "vendorDetails",
    component: VendorDetails,
  },
  {
    path: "./advanceList",
    exact: true,
    name: "advanceList",
    component: AdvanceList,
  },
  { path: "./dueList", exact: true, name: "dueList", component: DueList },
  {
    path: "./ledgerList",
    exact: true,
    name: "ledgerList",
    component: LedgerList,
  },
  {
    path: "/contractRoutes",
    exact: true,
    name: "contractRoutes",
    component: RouteList,
  },
  {
    path: "/clientContracts",
    exact: true,
    name: "clientContracts",
    component: ContractList,
  },
  { path: "/addRoute", exact: true, name: "addRoute", component: AddRoute },
  // { path: "/Nav", exact: true, name: "vehicles", component: Vehicles },
  // { path: "/EditPage", exact: true, name: "EditVehicle", component: EditVehicle },

  {
    path: "/addContract",
    exact: true,
    name: "addContract",
    component: addContract,
  },
  {
    path: "/editContract",
    exact: true,
    name: "editContract",
    component: editContract,
  },
  {
    path: "/clientDetails",
    exact: true,
    name: "clientDetails",
    component: ClientDetails,
  },
  {
    path: "/contractDetails",
    exact: true,
    name: "contractDetails",
    component: ContractDetails,
  },
  {
    path: "/deliveryOrderList",
    exact: true,
    name: "deliveryOrderList",
    component: DeliveryOrderList,
  },
  {
    path: "/fleetcontractdeliveryOrderList",
    exact: true,
    name: "deliveryOrderList",
    component: FleetContractDeliveryOrderList,
  },
  {
    path: "/deliveryOrderDetails",
    exact: true,
    name: "deliveryOrderDetails",
    component: DeliveryOrderDetails,
  },
  {
    path: ["/addDeliveryOrder", "/updateDeliveryOrder"],
    exact: true,
    name: "addDeliveryOrder",
    component: AddDeliveryOrder,
  },
  {
    path: "/editDeliveryOrder",
    path:"/createDeliveryOrder",
    exact:true,
    name:"CreateDeliveryOrder",
    component:CreateDeliveryOrder
  },
  {
    path:"/editDeliveryOrder",
    exact: true,
    name: "EditDeliveryOrder",
    component: EditDeliveryOrder,
  },
  {
    path: "/routeDetails",
    exact: true,
    name: "routeDetails",
    component: RouteDetails,
  },
  { path: "/indent", exact: true, name: "indent", component: Indent },
  { path: "/addIndent", exact: true, name: "addIndent", component: AddIndent },
  {
    path: "/companyUsers",
    exact: true,
    name: "companyUsers",
    component: CompanyUsers,
  },
  {
    path: "/fleetUsers",
    exact: true,
    name: "fleetUsers",
    component: FleetUsers,
  },
  { path: "/addUser", exact: true, name: "addUser", component: AddUser },
  { path: "/groups", exact: true, name: "groups", component: Groups },
  { path: "/groupView", exact: true, name: "groupView", component: GroupView },
  {
    path: ["/addGroup", "/updateGroup"],
    exact: true,
    name: "addGroup",
    component: AddGroup,
  },
  {
    path: "/journal-transactions",
    exact: true,
    name: "journal-transactions",
    component: JournalTransactions,
  },
  {
    path: ["/addJournalTransaction", "/updateJournalTransaction"],
    exact: true,
    name: "addJournalTransaction",
    component: AddJournalTransaction,
  },
  {
    path: "/fleetVendor",
    exact: true,
    name: "fleetVendor",
    component: FleetVendor,
  },
  { path: "/addFleet", exact: true, name: "addFleet", component: AddFleet },
  {
    path: "/servicevendor",
    exact: true,
    name: "servicevendor",
    component: ServiceVendor,
  },
  {
    path: "/addService",
    exact: true,
    name: "addService",
    component: AddService,
  },
  {
    path: "/servicecontractList",
    exact: true,
    name: "servicecontractList",
    component: ServiceContractList,
  },
  {
    path: "/addserviceContract",
    exact: true,
    name: "addserviceContract",
    component: AddServiceContract,
  },
  {
    path: "/serviceindentList",
    exact: true,
    name: "servicecontractList",
    component: ServiceIndentList,
  },
  {
    path: "/serviceDetails",
    exact: true,
    name: "serviceDetails",
    component: ServiceDetails,
  },
  {
    path: "/servicecontractDetails",
    exact: true,
    name: "servicecontractDetails",
    component: ServiceContractDetails,
  },
  { path: "/sites", exact: true, name: "sites", component: Sites },
  { path: "/addSite", exact: true, name: "addSite", component: AddSite },
  { path: "/viewSite", exact: true, name: "viewSite", component: ViewSite },
  {
    path: "/fleetDetails",
    exact: true,
    name: "fleetDetails",
    component: FleetDetails,
  },
  {
    path: "/editFleetDetails",
    exact: true,
    name: "editFleet",
    component: EditFleet,
  },
  {
    path: "/FleetContractList",
    exact: true,
    name: "fleetcontractList",
    component: FleetContractList,
  },
  {
    path: "/fleetCustomercontractList",
    exact: true,
    name: "FleetCustmerContract",
    component: FleetCustmerContract,
    
  },
  {
    path: "/AddFleetContract",
    exact: true,
    name: "addfleetContract",
    component: AddFleetContract,
  },
  {
    path: "/AddFleetCustomerContract",
    exact: true,
    name: "AddFleetCustomerContract",
    component: AddFleetCustomerContract,
  },
  {
    path: "/editfleetcontract",
    exact: true,
    name: "EditFleetContract",
    component: EditFleetContract
  },
  { path: "/myFleet", exact: true, name: "myFleet", component: MyFleet },
  {
    path: "/myFleetVehicles",
    exact: true,
    name: "myFleetVehicles",
    component: MyFleetHealthCare,
  },

  {
    path: "/FleetContractDetails",
    exact: true,
    name: "fleetcontractDetails",
    component: FleetContractDetails,
  },
  {
    path: "/VehicleTracking",
    exact: true,
    name: "VehicleTracking",
    component: VehicleTracking,
  },
  {
    path: "/liveVehicleTracking",
    exact: true,
    name: "VehicleTracking",
    component: liveVehicleTracking,
  },
  {
    path: "/driverTripsheets",
    exact: true,
    name: "driverTripsheets",
    component: DriverTripsheets,
  },
  {
    path: "/invoiceList",
    exact: true,
    name: "invoiceList",
    component: InvoiceList,
  },
  {
    path: "/generateInvoice",
    exact: true,
    name: "generateInvoice",
    component: GenerateInvoice,
  },
  {
    path: "/viewInvoice",
    exact: true,
    name: "viewInvoice",
    component: ViewInvoice,
  },
  {
    path: "/createInvoice",
    exact: true,
    name: "invoices",
    component: CreateInvoice,
  },
  {
    path: "/viewReceivables",
    exact: true,
    name: "receivables",
    component: ViewReceivables,
  },
  {
    path: "/updatePayments",
    exact: true,
    name: "updatePayments",
    component: UpdatePayments,
  },
  {
    path: "/billingAndPayments",
    exact: true,
    name: "billingAndPayments",
    component: BillingAndPayments,
  },
  {
    path: "/viewPayment",
    exact: true,
    name: "viewPAyment",
    component: ViewPayment,
  },
  {
    path: "/serviceProviderPayments",
    exact: true,
    name: "serviceProviderPayments",
    component: ServiceProviderPayments,
  },
  {
    path: "/vendorPayments",
    exact: true,
    name: "vendorPayments",
    component: VendorPayments,
  },
  {
    path: "/vendorIndents",
    exact: true,
    name: "vendorIndents",
    component: VendorIndents,
  },
  {
    path: "/generatePaymentVoucher",
    exact: true,
    name: "generatePaymentVoucher",
    component: GenerateVoucher,
  },
  {
    path: "/allRoutes",
    exact: true,
    name: "allRoutes",
    component: AllRoutes,
  },
  {
    path: "/pilotPayments",
    exact: true,
    name: "pilotPayments",
    component: PilotPayments,
  },
  {
    path: "/pilotLedger",
    exact: true,
    name: "pilotLedger",
    component: PilotLedger,
  },
  {
    path: "/pilotTrips",
    exact: true,
    name: "pilotTrips",
    component: PilotTrips,
  },
  {
    path: "/addAdvance",
    exact: true,
    name: "addAdvance",
    component: AddAdvance,
  },
  {
    path: "/addExpense",
    exact: true,
    name: "addExpense",
    component: AddExpense,
  },
  {
    path: "/pettyCash",
    exact: true,
    name: "pettyCash",
    component: PettyCash,
  },
  {
    path: "/fleetVendorPayments",
    exact: true,
    component: FleetVendorPayments,
  },
  {
    path: "/fleetVendorPaymentsDetails",
    exact: true,
    component: VendorPaymentsDetails,
  },
  {
    path: "/addPettycashPayment",
    exact: true,
    name: "addPettycashPayment",
    component: AddPettyCashPayment,
  },
  {
    path: "/pilotSettlements",
    exact: true,
    name: "pilotSettlements",
    component: PilotSettlements,
  },
  {
    path: "/settlementTrips",
    exact: true,
    name: "settlementTrips",
    component: SettlementTrips,
  },
  {
    path: "/banks",
    exact: true,
    name: "banks",
    component: OwnerBanks,
  },
  {
    path: "/addBank",
    exact: true,
    name: "addBank",
    component: AddBank,
  },
  {
    path: "/reqdetail",
    exact: true,
    name: "Request",
    component: Request,
  },

  //dispatch
  {
    path: "/requestlist",
    exact: true,
    name: "RequestList",
    component: RequestList,
  },
  {
    path: "/dispatchrequestlist",
    exact: true,
    name: "VehicleList",
    component: VehicleList,
  },
  {
    path: "/dispatchtransferlist",
    exact: true,
    name: "TransferList",
    component: TransferList,
  },
  {
    path: "/dispatchremovelist",
    exact: true,
    name: "RemoveList",
    component: RemoveList,
  },
  {
    path: "/dispatchexchangelist",
    exact: true,
    name: "ExchangeList",
    component: ExchangeList,
  },

  //fleet Head
  {
    path: "/vehiclerequestlist",
    exact: true,
    name: "VehcileReqVerify",
    component: VehcileReqVerify,
  },
  // {
  //   path: "/newvechilelist",
  //   exact: true,
  //   name: "NewVehcileList",
  //   component: NewVehcileList,
  // },
  {
    path: "/approvalrequestlist",
    exact: true,
    name: "NewVehcileList",
    component: NewVehcileList,
  },
  {
    path: "/approvaltransferlist",
    exact: true,
    name: "TransferVechileList",
    component: TransferVechileList,
  },
  {
    path: "/approvalremovelist",
    exact: true,
    name: "RemoveVechileList",
    component: RemoveVehcileList,
  },
  {
    path: "/approvalexchangelist",
    exact: true,
    name: "ExchangeVechileList",
    component: ExchangeVechileList,
  },

  //constrol room
  {
    path: "/vehiclerequestverificationlist",
    exact: true,
    name: "CtrlVehcileRequestVerify",
    component: CtrlVehcileRequestVerify,
  },
  {
    path: "/controlrequestlist",
    exact: true,
    name: "CtrlNewVehcileList",
    component: CtrlNewVehcileList,
  },
  {
    path: "/controltransferlist",
    exact: true,
    name: "CtrlTransferVehcileList",
    component: CtrlTransferVehcileList,
  },
  {
    path: "/controlremovelist",
    exact: true,
    name: "CtrlRemoveVehcileList",
    component: CtrlRemoveVehcileList,
  },
  {
    path: "/controlexchangelist",
    exact: true,
    name: "CtrlExchangeVehcileList",
    component: CtrlExchangeVehcileList,
  },

  // {
  //   path: "/vechilereqverify",
  //   exact: true,
  //   name: "VehcileReqVerify",
  //   component: VehcileReqVerify,
  // },

  { path: "/reports", exact: true, name: "reports", component: Report },
  // { path: '/fleetVendorContractList', exact: true, name: "fleetVendorContractList", component: FleetVendorContractList},
  // { path: '/addFleetVendorContract', exact: true, name: 'addFleetVendorContract', component: AddFleetVendorContract}

  {
    path: "/AllContracstsLists",
    exact: true,
    name: "AllContractsLists",
    component: AllContractsList,
  },
  {
    path: "/allDeliveryOrdersList",
    exact: true,
    name: "allDeliveryOrderList",
    component: AllDeliveryOrderList,
  },
  {
    path: "/DeliveryOrdersDetails",
    exact: true,
    name: "deliveryOrdersDetails",
    component: DeliveryOrdersDetails,
  },
  {
    path: "/contractsListdetails",
    exact: true,
    name: "contractsListdetails",
    component: AllContractsListdetails,
  },
  {
    path: "/fuelodosummary",
    exact: true,
    name: "fuelodosummary",
    component: FuelOdoSummay,
  },
  {
    path: "/myvehicle",
    exact: true,
    name: "myvehicle",
    component: VehiclesList,
  },
  {
    path: "/update-vehicle",
    exact: true,
    name: "EditVehicle",
    component: EditVehicle,
  },
  {
    path: "/tollsummary",
    exact: true,
    name: "tollsummary",
    component: Tollsummary,
  },
  // {path:"/driverTripSheet",exact:true,name:'driverTripSheet',component:DriverTripSheet},
  {
    path: "/DistanceSummaryReport",
    exact: true,
    name: "DistanceSummary-Report",
    component: DistanceSummaryReport,
  },
  {
    path: "/Breakdownreport",
    exact: true,
    name: "Breakdown-Report",
    component: Breakdownreport,
  },
  {
    path: "/EmptyVehicleWaitingReport",
    exact: true,
    name: "Breakdown-Report",
    component: Emptyvehiclewaitingreport,
  },
  {
    path: "/ProfitandLossReport",
    exact: true,
    name: "ProfitandLoss-Report",
    component: ProfitAndLossReport,
  },
  {
    path: "/Speedreport",
    exact: true,
    name: "Speed-Report",
    component: SpeedReport,
  },
  {
    path: "/Speedwisereport",
    exact: true,
    name: "Speedwise-Report",
    component: SpeedwiseReport,
  },
  {
    path: "/IgnitionReport",
    exact: true,
    name: "Ignition-Report",
    component: IgnitionReport,
  },
  {
    path: "/Distancereport",
    exact: true,
    name: "Distance-Report",
    component: DistanceReport,
  },
  {
    path: "/TrackhistoryReport",
    exact: true,
    name: "Track History-Report",
    component: TrackHistoryReport,
  },
  {
    path: "/HourlypingReport",
    exact: true,
    name: "Hourly Ping-Report",
    component: HourlyPingReport,
  },
  {
    path: "/TripsReport",
    exact: true,
    name: "TripsReport",
    component: TripsReport,
  },
  {
    path: ["/fuelreport"],

    exact: true,

    name: "fuelReport",

    component: FuelReport,
  },

  {
    path: ["/FuelConsumptionReport"],

    exact: true,

    name: "fuelConsumption",

    component: FuelConsumption,
  },
  {
    path: "/edit-profile",
    exact: true,
    name: "edit-profile",
    component: EditUserProfile,
  },
  {
    path: "/benchmark",
    exact: true,
    name: "benchmark",
    component: BenchmarksList,
  },
  {
    path: "/equipments",
    exact: true,
    name: "equipments",
    component: InventoryCategory,
  },
  {
    path: "/registration",

    exact: true,

    name: "registration",

    component: RegistrationForm,
  },
  {
    path: "/verification",

    exact: true,

    name: "verification",

    component: OtpVerification,
  },
  {
    path: "/success",

    exact: true,

    name: "success",

    component: ForSuccessMessage,
  },
  {
    path: "/forgot-password",

    exact: true,

    name: "forgot-password",

    component: ForgotPassword,
  },
  {
    path: "/storeInventory",
    exact: true,
    name: "storeInventory",
    component: ForStoreInventory,
  },
  {
    path: "/inventoryTransactions",
    exact: true,
    name: "inventoryTransactions",
    component: CollapsibleTableForTransactions,
  },
  {
    path: "/myDevices",
    exact: true,
    name: "myDevices",
    component: MyDevice,
  },
  {
    path: "/addDevice",
    exact: true,
    name: "addDevice",
    component: AddDevice,
  },

  {
    path: "/deviceViewDetails",
    exact: true,
    name: "deviceViewDetails",
    component: DeviceViewDetails,
  },
  {
    path: "/deviceLiveData",
    exact: true,
    name: "deviceLiveData",
    component: DeviceLiveData,
  },

  
];
export default routes;
