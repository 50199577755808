import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import "../../common.css";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {Button} from "@mui/material";
// import * as Fields from "../../sharedComponents";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
// import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import _ from "lodash";
// import EditIcon from "@material-ui/icons/Edit";
// import CloseIcon from "@mui/icons-material/Close";
// import * as Components from "../../sharedComponents";
// import * as CONFIG from "../../config/G/lobalConstants";
// import { useHistory } from "react-router-dom";
// import CircularLoading from "../../components/loader/circularLoading";
// import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import * as Fields from "../../sharedComponents";
import { Grid } from "@material-ui/core";
import ValidateFields from "../../validations/validateFields";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import moment from "moment";
import Util from "../../utils";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  addUser_btn: {
    textTransform: "none",
    backgroundColor: "#649B42",
    "&:hover": {
      backgroundColor: "#649B42",
    },
    padding: "8px 15px",
    marginRight: "12px",
  },
  header_buttons: {
    padding: "0px 15px",
  },
  site_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    marginRight: "15px",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  clear_button: {
    padding: "6px 15px",
    background: "#D3710F0D",
    border: "1px solid #D3710F",
    color: "#D3710F",
    "&:hover": {
      background: "#D3710F0D",
    },
  },
  filter_buttons: {
    textAlign: "right",
    padding: "0px 15px",
  },
  dialogPaper: {
    minWidth: '500px', // Set the minimum width to 300px
    maxWidth: '600px', // Set the maximum width to 500px
  },
  divider: {
    borderTop: '2px solid #F15F4C', // Customize the thickness and color of the divider
  },
  dialogTitle:{
    cursor:'move',
    textAlign:'center',
    color:'#F15F4C'
  }
}));
export const SubscriptionOfDevice = (props) => {
  const formFields = {
    
    deviceImei: {
      name: "deviceImei",
      label: "Device (IMEI) Number",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern: "ALPHABET_with_numeric_label",
      isValid: true,
      errorMsg: "Please select device type",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },
    subscriptionRate: {
        name: "subscriptionRate",
        label: "Subscription Price",
        value: "",
        isValid: true,
        topLabel: true,
        validationRequired: true,
        validPattern: "NUMERIC",
        errorMsg: "Please enter subscription price",
        ref: useRef(),
        type: "number",
        // labelColor: "#397296",
        // image: subscriptionRate,
      },
      subscriptionStatus: {
        name: "subscriptionStatus",
        label: "Subscription Status",
        value: { value: "", label: "" },
        options: [
          { value: "1", label: "Active" },
          { value: "0", label: "In Active" },
        ],
        topLabel: true,
        validationRequired: true,
        validPattern : 'ALPHABET_with_numeric_label',
        isValid: true,
        errorMsg: "Please select subscription status",
        ref: useRef(),
        allowClear: true,
        // labelColor: "#397296",
        // image: subscriptionStatus,
      },
     subscriptionDate: {
      name: "subscriptionDate",
      label: "Subscription Date",
      value: "",
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select subscription date",
      validPattern: "DATE",
      topLabel: true,
      ref: useRef(),
      disabledDate: (d) => !d || d.isAfter(new Date()),
    //   labelColor: "#397296",
    //   image: subscriptionDate,
      // disabledDate: (d) => !d || d.isBefore(new Date()),
    },
    subscriptionExpDate: {
      name: "subscriptionExpDate",
      label: "Subscription Expiry Date",
      value: "",
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select expiry date",
      validPattern: "DATE",
      topLabel: true,
      ref: useRef(),
    //   labelColor: "#397296",
    //   image: subscriptionExpiryDate,
      // disabledDate: (d) => !d || d.isBefore(new Date()),
    },
    subscriptionType: {
      name: "subscriptionType",
      label: "Subscription Type",
      value: { value: "", label: "" },
      options: [
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Annually", label: "Annually" },
      ],
      topLabel: true,
      validationRequired: true,
      validPattern : 'ALPHABET_with_numeric_label',
      isValid: true,
      errorMsg: "Please select subscription type",
      ref: useRef(),
      allowClear: true,
    //   labelColor: "#397296",
    //   image: subscriptionType,
    },
  };
  const [fields, setFields] = useState(formFields);
  const util = new Util()
  const [open, setOpen] = useState(props.open);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  let classes = useStyles();
  let placeRefCircle = useRef();
  const [spinner, setSpinner] = useState(false);
  const fileInputRef = useRef(null);
  const validateFields = new ValidateFields();
  const Service = global.service
  useEffect(async()=>{
    if(props.assignData){
      let newFields = _.cloneDeep(fields);
      newFields.deviceImei.value = {label:props?.assignData?.deviceId,value:props?.assignData?.deviceImei}
      if(props?.assignData.subscriptionDate != null && props?.assignData.subscriptionDate != undefined && props?.assignData.subscriptionDate != ''){
        newFields.subscriptionDate.value = moment(props?.assignData.subscriptionDate).clone(
          "YYYY-MM-DD"
        );
      }
      if(props?.assignData.expiryDate != null && props?.assignData.expiryDate != undefined && props?.assignData.expiryDate != ''){
        newFields.subscriptionExpDate.value = moment(props?.assignData.expiryDate).clone(
          "YYYY-MM-DD"
        );
      }
      if(props.assignData.subscriptionType != null && props.assignData.subscriptionType != undefined && props.assignData.subscriptionType != null){
        newFields.subscriptionType.value = {
          label: props.assignData.subscriptionType,
          value: props.assignData.subscriptionType,
        }
      if(props.assignData.subscriptionStatus != null && props.assignData.subscriptionStatus != undefined && props.assignData.subscriptionStatus != null){
            newFields.subscriptionStatus.value = {
                label: props.assignData.subscriptionStatus,
                value: props.assignData.subscriptionStatus == '1' ? 'Active' : 'In Active',
            }
        }
        if(props?.assignData.subscriptionPrice != null && props?.assignData.subscriptionPrice != undefined && props?.assignData.subscriptionPrice != ''){
            newFields.subscriptionRate.value = Number(props?.assignData?.subscriptionPrice)
          }
    }
      setFields(newFields)
    }
  },[props?.assignData])
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.length > 0) {
      newFields[name]["isValid"] = true;
    }
    newFields[name]["value"] = value;
    setFields(newFields);
  };
  const timeStampChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    newFields[name]["value"] = value;
    newFields["subscriptionDate"]["disabledDate"] = (d) => !d || d.isBefore(value);
      if(name == 'subscriptionDate'){
        newFields["subscriptionExpDate"]["disabledDate"] = (d) => !d || d.isBefore(value);
      }
    newFields[name]["isValid"] = true;
    if (newFields[name]["validationRequired"] && value == null) {
      newFields[name]["isValid"] = false;
      
    }
    setFields(newFields);
  };
  const ImageChangeHandler = () => {
    fileInputRef.current.click();
  };
  const autoCompleteChangeHandler = async (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.label.length > 0) {
      newFields[name]["isValid"] = true;
    }
    newFields[name]["value"] = value;
    setFields(newFields);
  };
  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     setImg(URL.createObjectURL(selectedFile));
  //   }
  //   const formData = new FormData();
  //   formData.append("file", selectedFile);
  //   formData.append("id", ownerdata.id);
  // }

  

  const subscriptionOfDevice = async (fields) => {
    let dataStatus = await validateFields.validateFieldsData(fields);
    if (dataStatus.status) {
      setSpinner(true)
      let object = {
        deviceId: props.assignData.deviceId,
        // lostDate : util.formatDate(fields.lostDate.value)
        companyId : props.assignData.companyId,
        subscriptionType : fields.subscriptionType?.value?.value,
        status : (props.assignData.subscriptionStatus != null && props.assignData.subscriptionStatus != undefined && props.assignData.subscriptionStatus != null) ? props.assignData.subscriptionStatus : fields.subscriptionStatus.value?.value ,
        subscriptionDate : util.formatDate(fields.subscriptionDate.value),
        expiryDate :util.formatDate(fields.subscriptionExpDate.value),
        subscriptionRate : fields.subscriptionRate.value,
      };
      await Service.updateSubscriptionToDevice(object)
        .then((res) => {
          setSpinner(false);
          if (res.status) {
            setAlertData({
              open: true,
              severity: "success",
              message: res.message,
            });
            setTimeout(() => {
              closeAlert();
              handleClose();
              window.location.reload();
            }, 3000);
          } else {
            setAlertData({
              open: true,
              severity: "error",
              message: res.message,
            });
          }
        })
        .catch((e) => {
          console.log("Error......", e);
        });
    } else {
      let newFields = _.cloneDeep(fields);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFields(newFields);
    }
    // props.onClose();
  };
  const handleClose = () => {
    setOpen(false);
    props.onClose();
    let newField = _.cloneDeep(fields);
    setFields(newField);
  };

  return (
    <div >
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        classes={{ paper: classes.dialogPaper }} 
        maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
        fullWidth={true}
      >
        {alertData.open ? (
          <div className={classes.alertBox}>
            <AlertMessage
              severity={alertData.severity}
              message={alertData.message}
              closeAlert={closeAlert}
            />
          </div>
        ) : null}
        <DialogTitle
           className={classes.dialogTitle}
          id="draggable-dialog-title"
        >
         Subscription
        </DialogTitle>
        <DialogContent dividers className={classes.divider}>
          <DialogContentText>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} >
                <Fields.AntSelectableSearchField
                  fieldData={fields.deviceImei}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled = {true}
                />
              </Grid>
              <Grid item xs={6}>
                      <Fields.AntSelectableSearchField
                        fieldData={fields.subscriptionType}
                        autoCompleteChangeHandler={(value, name) =>
                          autoCompleteChangeHandler(value, name)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Fields.AntSelectableSearchField
                        fieldData={fields.subscriptionStatus}
                        autoCompleteChangeHandler={(value, name) =>
                          autoCompleteChangeHandler(value, name)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Fields.AntdDatePickerField
                        fieldData={fields.subscriptionDate}
                        timeStampChangeHandler={timeStampChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Fields.AntdDatePickerField
                        fieldData={fields.subscriptionExpDate}
                        timeStampChangeHandler={timeStampChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Fields.InputField
                        fieldData={fields.subscriptionRate}
                        inputChangeHandler={inputChangeHandler}
                        variant="outlined"
                      />
                    </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => forSubmitingUserData(fields)}
                  color="primary"
                >
                  Submit
                </Button> */}
          <Grid
            container
            spacing={2}
            className="details_container_content"
            style={{ marginRight: 25 }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}></Grid>
                {/* <Grid item xs={3}></Grid> */}
                {/* <Grid item xs={2}></Grid> */}
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className="cancel_button"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className="save_button"
                    onClick={() => subscriptionOfDevice(fields)}
                    startIcon={
                      spinner ? (
                        <CircularProgress size={20} color={"#fff"} />
                      ) : null
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
