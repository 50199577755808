import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import "../../common.css";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import * as Fields from "../../sharedComponents";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
// import CloseIcon from "@mui/icons-material/Close";
import * as Components from "../../sharedComponents";
import * as CONFIG from "../../config/GlobalConstants";
import { useHistory } from "react-router-dom";
import CircularLoading from "../../components/loader/circularLoading";
import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import * as Fields from "../../sharedComponents";
import { Grid } from "@material-ui/core";
import ValidateFields from "../../validations/validateFields";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import { number } from "prop-types";
import MultiselectFields from "../../sharedComponents/antd/MultiselectFields";
import Util from "../../utils";
import moment from "moment";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  addUser_btn: {
    textTransform: "none",
    backgroundColor: "#649B42",
    "&:hover": {
      backgroundColor: "#649B42",
    },
    padding: "8px 15px",
    marginRight: "12px",
  },
  header_buttons: {
    padding: "0px 15px",
  },
  site_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    marginRight: "15px",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  clear_button: {
    padding: "6px 15px",
    background: "#D3710F0D",
    border: "1px solid #D3710F",
    color: "#D3710F",
    "&:hover": {
      background: "#D3710F0D",
    },
  },
  filter_buttons: {
    textAlign: "right",
    padding: "0px 15px",
  },
  dialogPaper: {
    minWidth: "500px", // Set the minimum width to 300px
    maxWidth: "600px", // Set the maximum width to 500px
  },
  divider: {
    borderTop: "2px solid #F15F4C", // Customize the thickness and color of the divider
  },
  dialogTitle: {
    cursor: "move",
    textAlign: "center",
    color: "#F15F4C",
  },
}));
export const AssignDeviceToTruck = (props) => {
  const formFields = {
    companyName: {
      name: "companyName",
      label: "Company Name",
      value: {label: "",value: "", },
      options: [],
      topLabel: true,
      validationRequired: false,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      //   errorMsg: "Please select company name",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },
    providerName: {
      name: "providerName",
      label: "Provider Name",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: false,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      //   errorMsg: "Please select provider name",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },
    regiNum: {
      name: "regiNum",
      label: "Registration Number",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern: "ALPHABET_with_numeric_label",
      isValid: true,
      errorMsg: "Please select registration number",
      //   ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },
    deviceType: {
      name: "deviceType",
      label: "Device Type",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: false,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      //   errorMsg: "Please select device type",
      // ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },
    deviceImei: {
      name: "deviceImei",
      label: "Device (IMEI) Number",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: false,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      //   errorMsg: "Please select device type",
      // ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },

    odoReading: {
      name: "odoReading",
      label: "Odo Reading",
      value: "",
      isValid: true,
      topLabel: true,
      validationRequired: true,
      validPattern: "SPECIAL_CHARS_DESC",
      errorMsg: "Please enter odo reading",
      ref: useRef(),
      type: "number",
      labelColor: "#397296",
    },
    calculators: {
      name: "calculators",
      label: "Calculators",
      options: [],
      value: [],
      actualValue: [],
      data: [],
      topLabel: true,
      // validationRequired: true,
      // validPattern: "ALPHABET_with_numeric_label",
      // isValid: true,
      placeholder: "Select Calculators",
      allowClear : true,
      labelColor: "#397296",
    },
    streams: {
      name: "streams",
      label: "Streams",
      options: [],
      value: [],
      actualValue: [],
      data: [],
      topLabel: true,
      // validationRequired: true,
      // validPattern: "ALPHABET_with_numeric_label",
      // isValid: true,
      placeholder: "Select Calculators",
      allowClear : true,
      labelColor: "#397296",
    },
    plugins: {
      name: "plugins",
      label: "Plugins",
      options: [],
      value: [],
      actualValue: [],
      data: [],
      topLabel: true,
      // validationRequired: true,
      // validPattern: "ALPHABET_with_numeric_label",
      // isValid: true,
      placeholder: "Select Calculators",
      allowClear : true,
      labelColor: "#397296",
    },
    simNum: {
      name: "simNum",
      label: "Sim Number",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern: "ALPHABET_with_numeric_label",
      isValid: true,
      errorMsg: "Please assign sim to this device",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
    },
     installDate: {
      name: "installDate",
      label: "Device Install Date",
      value: '',
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select install date",
      validPattern: "DATE",
      topLabel: true,
      ref: useRef(),
      labelColor: "#397296",
      // image: deviceInstallDate,
      disabledDate: (d) => !d || d.isAfter(new Date()),
    },
  };
  const [fields, setFields] = useState(formFields);
  const [open, setOpen] = useState(props.open);
  let Service = global.service;
  const util = new Util()
  const validateFields = new ValidateFields();
  const [optionsList, setOptionsList] = useState([]);
  const [streamOptionsList, setStreamOptionsList] = useState([]);
  const [pluginOptionsList, setPluginOptionsList] = useState([]);


  useEffect(async () => {
    if (props?.assignData) {
      let newFields = _.cloneDeep(fields);
      newFields.companyName.value = {
        label: props.assignData.companyId,
        value: props.assignData.companyName,
      };
      newFields.providerName.value = {
        label: props.assignData.deviceProviderId,
        value: props.assignData.deviceProviderName,
      };
      newFields.deviceType.value = {
        label: props.assignData.deviceTypeId,
        value: props.assignData.deviceType,
      };
      newFields.deviceImei.value = {
        label: props.assignData.deviceId,
        value: props.assignData.deviceImei,
      };
      newFields.simNum.value = {
        label: props.assignData.simId,
        value: props.assignData.simNum,
      };
      
      await Service.getAllCalculators()
      .then((res) => {
        if (res.data.length > 0) {
          let calculatorsOptions = res.data.map((each) => {
            return { label: each.name, value: each.id };
          });
          let listOptions = res.data.map((item) => {
            return { id: item.id, name: item.name };
          });
          if(props.assignData.calculators != null){
            let idsToFind = props.assignData.calculators.split(",")
            const filteredObjects =calculatorsOptions.filter(obj => idsToFind.includes(String(obj.value)));
            newFields["calculators"]["value"] = props.assignData.calculators; 
            newFields["calculators"]["actualValue"] = filteredObjects;
          }
          newFields.calculators.options = calculatorsOptions;
          setOptionsList(listOptions);
        }
      })
      .catch((e) => {
        console.log("Error......", e);
      });

      await Service.getAllStreams()
      .then((res) => {
        if (res.data.length > 0) {
          let streamOptions = res.data.map((each) => {
            return { label: each.name, value: each.id };
          });
          let listOptions = res.data.map((item) => {
            return { id: item.id, name: item.name };
          });
          if(props.assignData.streams != null){
            let idsToFind = props.assignData.streams.split(",")
            const filteredObjects =streamOptions.filter(obj => idsToFind.includes(String(obj.value)));
            newFields["streams"]["value"] = props.assignData.streams; 
            newFields["streams"]["actualValue"] = filteredObjects;
          }
          newFields.calculators.options = streamOptions;
          setStreamOptionsList(listOptions);
        }
      })
      .catch((e) => {
        console.log("Error......", e);
      });
      await Service.getAllPlugins()
      .then((res) => {
        if (res.data.length > 0) {
          let pluginOptions = res.data.map((each) => {
            return { label: each.name, value: each.id };
          });
          let listOptions = res.data.map((item) => {
            return { id: item.id, name: item.name };
          });
          if(props.assignData.plugins != null){
            let idsToFind = props.assignData.plugins.split(",")
            const filteredObjects =pluginOptions.filter(obj => idsToFind.includes(String(obj.value)));
            newFields["plugins"]["value"] = props.assignData.plugins; 
            newFields["plugins"]["actualValue"] = filteredObjects;
          }
          newFields.calculators.options = pluginOptions;
          setPluginOptionsList(listOptions);
        }
      })
      .catch((e) => {
        console.log("Error......", e);
      });

      if(props.assignData.regiNum != null && props.assignData.regiNum != undefined && props.assignData.truckId != null && props.assignData.truckId != undefined){
        newFields.regiNum.value = {
          label: props.assignData.truckId,
          value: props.assignData.regiNum,
        }
      }else{
        await Service.getVehiclesByCompanyId(props.assignData.companyId)
        .then((res) => {
          if (res.data.length > 0) {
            let truckOptions = res.data.map((each) => {
              return { label: each.regi_no, value: each.id };
            });
            newFields.regiNum.options = truckOptions;
          }
        })
        .catch((e) => {
          console.log("Error......", e);
        });
      }

      if(props?.assignData.installDate != null && props?.assignData.installDate != undefined && props?.assignData.installDate != ''){
        newFields.installDate.value = moment(props?.assignData.installDate).clone(
          "YYYY-MM-DD"
        );
      }
      if(props.assignData.odoReading != null && props.assignData.odoReading != undefined && props.assignData.odoReading != null && props.assignData.odoReading != undefined){
        newFields.odoReading.value = props.assignData.odoReading
      }
      // await Service.getAllVehicles({ownVehicles:true,marketVehicles:true}).then((res)=>{
      //     if(res.vehicles.length > 0){
      //         let truckOptions = res.vehicles.map((each)=>{
      //            return {'label':each.registrationNumber,'value':each.id}
      //         })
      //         newFields.regiNum.options = truckOptions
      //         console.log('newFields...',newFields)
      //     }
      // }).catch((e)=>{
      //     console.log('Error......',e)
      // })
      
      setFields(newFields);
    }
  }, [props?.assignData]);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const vehicleSelectHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    newFields[name]["value"] = value.join(","); 
    let data = [];
    let filterOptions = newFields?.calculators?.options?.filter((i) =>
      value.map((j) => {
        if (j === i.id) {
          return data.push(i);
        }
      })
    );
    newFields[name]["actualValue"] = value;
    newFields[name]["data"] = data;
    setFields(newFields)
  }
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  let classes = useStyles();
  let placeRefCircle = useRef();
  const [spinner, setSpinner] = useState(false);
  const decimalValidation = (value) => {
    const regex = new RegExp("^[0-9]+(.[0-9]{1,2})?$");
    return regex.test(value);
  };
  const otherThenalphaNumericValidation = (value) => {
    const regex = new RegExp("^[a-zA-Z0-9 ]*$");
    return regex.test(value);
  };
  const timeStampChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    newFields[name]["value"] = value;
    // newFields["devicePurDate"]["disabledDate"] = (d) => !d || d.isBefore(value);
      // if(name == 'devicePurDate'){
      //   newFields["installDate"]["disabledDate"] = (d) => !d || d.isBefore(value);
      // }
    newFields[name]["isValid"] = true;
    if (newFields[name]["validationRequired"] && value == null) {
      newFields[name]["isValid"] = false; 
    }
    setFields(newFields);
  };
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.length > 0) {
      newFields[name]["isValid"] = true;
    }
    if (name == "odoReading") {
      if (
        decimalValidation(value) === true ||
        value === "" ||
        otherThenalphaNumericValidation(value) === true
      ) {
        newFields[name]["value"] = value;
        setFields(newFields);
      }
    } else {
      newFields[name]["value"] = value;
      setFields(newFields);
    }
  };
  const autoCompleteChangeHandler = async (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.label.length > 0) {
      newFields[name]["isValid"] = true;
    }
    newFields[name]["value"] = value;
    setFields(newFields);
  };
  const handleClose = () => {
    setOpen(false);
    props.onClose();
    let newField = _.cloneDeep(fields);
    setFields(newField);
  };
  const assignVehicleData = async (fields) => {
    let dataStatus = await validateFields.validateFieldsData(fields);
    if (dataStatus.status) {
      setSpinner(true);
      let object = {
        deviceId: props.assignData.deviceId,
        truckId: props.assignData.truckId != null && props.assignData.truckId != undefined ? props.assignData.truckId :fields.regiNum.value?.value,
        odoReading: fields.odoReading.value,
        calculators : fields.calculators.value,
        deviceInstallDate: util.formatDate(fields.installDate.value),
        streams : fields.streams.value,
        plugins : fields.plugins.value
      };

      // console.log('object.....',object)

      await Service.assignDeviceToTruck(object)
        .then((res) => {
          setSpinner(false);
          if (res.status) {
            setAlertData({
              open: true,
              severity: "success",
              message: res.message,
            });
            setTimeout(() => {
              closeAlert();
              handleClose();
              window.location.reload();
            }, 3000);
          } else {
            setAlertData({
              open: true,
              severity: "error",
              message: res.message,
            });
          }
        })
        .catch((e) => {
          console.log("Error......", e);
        });
    } else {
      let newFields = _.cloneDeep(fields);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFields(newFields);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
        fullWidth={true}
      >
        {alertData.open ? (
          <div className={classes.alertBox}>
            <AlertMessage
              severity={alertData.severity}
              message={alertData.message}
              closeAlert={closeAlert}
            />
          </div>
        ) : null}
        <DialogTitle
          className={classes.dialogTitle}
          id="draggable-dialog-title"
        >
          Assign (Truck)
        </DialogTitle>
        <DialogContent dividers className={classes.divider}>
          <DialogContentText>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.companyName}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.providerName}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.regiNum}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled = {props.assignData.regiNum != null && props.assignData.regiNum != undefined }
                />
              </Grid>
              <Grid item xs={6}>
                <Fields.InputField
                  fieldData={fields.odoReading}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
               <Grid item xs={12}>
                    <Fields.AntdDatePickerField
                      fieldData={fields.installDate}
                      timeStampChangeHandler={timeStampChangeHandler}
                    />
                  </Grid>
              <Grid item xs={12}>
                  <MultiselectFields
                  fieldData={fields.calculators}
                  options={optionsList}
                  selectChangeHandler={vehicleSelectHandler}
                  mode={"multiple"}
                />
                  </Grid>
                  <Grid item xs={12}>
                  <MultiselectFields
                  fieldData={fields.streams}
                  options={streamOptionsList}
                  selectChangeHandler={vehicleSelectHandler}
                  mode={"multiple"}
                />
                  </Grid>
                  <Grid item xs={12}>
                  <MultiselectFields
                  fieldData={fields.plugins}
                  options={pluginOptionsList}
                  selectChangeHandler={vehicleSelectHandler}
                  mode={"multiple"}
                />
                  </Grid>
              <Grid item xs={6}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.deviceType}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.deviceImei}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.simNum}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => forSubmitingUserData(fields)}
                  color="primary"
                >
                  Submit
                </Button> */}
          <Grid
            container
            spacing={2}
            className="details_container_content"
            style={{ marginRight: 25 }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}></Grid>
                {/* <Grid item xs={3}></Grid> */}
                {/* <Grid item xs={2}></Grid> */}
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className="cancel_button"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className="save_button"
                    // onClick={() => forSubmitingUserData(fields)}
                    onClick={() => assignVehicleData(fields)}
                    startIcon={
                      spinner ? (
                        <CircularProgress size={20} color={"#fff"} />
                      ) : null
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
