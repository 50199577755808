import React, { useRef,useState } from "react";
import { DatePicker } from "antd";
import { makeStyles, FormLabel } from "@material-ui/core";
import moment from "moment";
import Util from "../../utils";
// import { AntdTimePicker } from "..";
import { TimePicker } from "antd";

const useStyles = makeStyles((theme) => ({
  dateRange: {
    width: "100%",
    padding: "7px",
    border: "1px solid #91b0c4",
    borderRadius: "4px",
  },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
  },
}));

const { RangePicker } = DatePicker;

const AntdTimePicker2 = (props) => {
  const [slectedTime,setSelectedTime] = useState('');
  const classes = useStyles();
  let util = new Util();
  const dateFormat = "YYYY/MM/DD";
  const pickerRef = useRef();
  return (
    <div>
      {props?.fieldData?.topLabel ? (
        <FormLabel
          className={classes.headLabel}
          required={props.fieldData.validationRequired}
        >
          {props.fieldData.label}
        </FormLabel>
      ) : null}
              <TimePicker
                format="HH:mm"
                style={{
                  marginTop: "8px",
                  width: "100%",
                  padding: "9px",
                  borderRadius: "4px",
                  border: "1px solid #91b0c4",
                  outline: "none",
                }}
                value={props?.fieldData?.value ? moment(props?.fieldData?.value, "HH:mm") : null}
                onChange={(e,a) => {
                  setSelectedTime(a);
                  props.onChange(a,props.fieldData.name)
                }
              }
              />
    </div>
  );
};

export default AntdTimePicker2;
